import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-gridexinfo',
  }),
  // link: new HttpLink({
  //   uri: 'http://173.208.142.67:8000/subgraphs/name/bentokenfinance/bch-gridexinfo',
  // }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})
export const clientBlock = new ApolloClient({
  link: new HttpLink({
    uri: 'https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-exchange',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})


export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://graphnode.benswap.cash/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://subgraphs.benswap.cash/subgraphs/name/bentokenfinance/bch-blocks',
  }),
  cache: new InMemoryCache(),
})
