import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect } from 'react'
import { client } from '../apollo/client'
import {
  // USER_TRANSACTIONS,
  USER_TRANSACTIONS_TYPE,
} from '../apollo/queries'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS'
const UPDATE_POSITIONS = 'UPDATE_POSITIONS '
const UPDATE_MINING_POSITIONS = 'UPDATE_MINING_POSITIONS'
const UPDATE_USER_POSITION_HISTORY = 'UPDATE_USER_POSITION_HISTORY'
const UPDATE_USER_PAIR_RETURNS = 'UPDATE_USER_PAIR_RETURNS'

const TRANSACTIONS_KEY = 'TRANSACTIONS_KEY'
const POSITIONS_KEY = 'POSITIONS_KEY'
const MINING_POSITIONS_KEY = 'MINING_POSITIONS_KEY'
const USER_SNAPSHOTS = 'USER_SNAPSHOTS'
const USER_PAIR_RETURNS_KEY = 'USER_PAIR_RETURNS_KEY'

const UserContext = createContext()

function useUserContext() {
  return useContext(UserContext)
}

function reducer(state, { type, payload }) {
  switch (type) {
    case UPDATE_TRANSACTIONS: {
      const { account, transactions } = payload
      return {
        ...state,
        [account]: {
          ...state?.[account],
          [TRANSACTIONS_KEY]: transactions,
        },
      }
    }
    case UPDATE_POSITIONS: {
      const { account, positions } = payload
      return {
        ...state,
        [account]: { ...state?.[account], [POSITIONS_KEY]: positions },
      }
    }
    case UPDATE_MINING_POSITIONS: {
      const { account, miningPositions } = payload
      return {
        ...state,
        [account]: { ...state?.[account], [MINING_POSITIONS_KEY]: miningPositions },
      }
    }
    case UPDATE_USER_POSITION_HISTORY: {
      const { account, historyData } = payload
      return {
        ...state,
        [account]: { ...state?.[account], [USER_SNAPSHOTS]: historyData },
      }
    }

    case UPDATE_USER_PAIR_RETURNS: {
      const { account, pairAddress, data } = payload
      return {
        ...state,
        [account]: {
          ...state?.[account],
          [USER_PAIR_RETURNS_KEY]: {
            ...state?.[account]?.[USER_PAIR_RETURNS_KEY],
            [pairAddress]: data,
          },
        },
      }
    }

    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

const INITIAL_STATE = {}

export default function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const updateTransactions = useCallback((account, transactions) => {
    dispatch({
      type: UPDATE_TRANSACTIONS,
      payload: {
        account,
        transactions,
      },
    })
  }, [])

  const updatePositions = useCallback((account, positions) => {
    dispatch({
      type: UPDATE_POSITIONS,
      payload: {
        account,
        positions,
      },
    })
  }, [])

  const updateMiningPositions = useCallback((account, miningPositions) => {
    dispatch({
      type: UPDATE_MINING_POSITIONS,
      payload: {
        account,
        miningPositions,
      },
    })
  }, [])

  const updateUserSnapshots = useCallback((account, historyData) => {
    dispatch({
      type: UPDATE_USER_POSITION_HISTORY,
      payload: {
        account,
        historyData,
      },
    })
  }, [])

  const updateUserPairReturns = useCallback((account, pairAddress, data) => {
    dispatch({
      type: UPDATE_USER_PAIR_RETURNS,
      payload: {
        account,
        pairAddress,
        data,
      },
    })
  }, [])

  return (
    <UserContext.Provider
      value={useMemo(
        () => [
          state,
          { updateTransactions, updatePositions, updateMiningPositions, updateUserSnapshots, updateUserPairReturns },
        ],
        [state, updateTransactions, updatePositions, updateMiningPositions, updateUserSnapshots, updateUserPairReturns]
      )}
    >
      {children}
    </UserContext.Provider>
  )
}

export function useUserTransactions(account) {
  const [state, { updateTransactions }] = useUserContext()
  const transactions = state?.[account]?.[TRANSACTIONS_KEY]
  useEffect(() => {
    async function fetchData(account) {
      try {
        // let result = await client.query({
        //   query: USER_TRANSACTIONS,
        //   variables: {
        //     user: account,
        //   },
        //   fetchPolicy: 'no-cache',
        // })


        // TO DO
        let MINTS = await client.query({
          query: USER_TRANSACTIONS_TYPE,
          variables: {
            user: account,
            type: "Mint"
          },
          fetchPolicy: 'cache-first',
        })
        let BURNS = await client.query({
          query: USER_TRANSACTIONS_TYPE,
          variables: {
            user: account,
            type: "Burn"
          },
          fetchPolicy: 'cache-first',
        })
        let SWAPS = await client.query({
          query: USER_TRANSACTIONS_TYPE,
          variables: {
            user: account,
            type: "Swap"
          },
          fetchPolicy: 'cache-first',
        })


        const mints_res = MINTS.data.transactions.map(t => ({
          id: t.id,
          transaction: {
            id: t.txId,
            timestamp: t.timestamp
          },
          timestamp: t.timestamp,
          pair: t.pair,
          to: t.sender,
          liquidity: '0',
          sender: t.sender,
          amount0: t.amount0,
          amount1: t.amount1,
          logIndex: '0',
          amountUSD: t.amountUSD,
          feeTo: '0',
          feeLiquidity: '0'
        }))
        const burns_res = BURNS.data.transactions.map(t => ({
          id: t.id,
          transaction: {
            id: t.txId,
            timestamp: t.timestamp
          },
          timestamp: t.timestamp,
          pair: t.pair,
          to: t.sender,
          liquidity: '0',
          sender: t.sender,
          amount0: t.amount0,
          amount1: t.amount1,
          logIndex: '0',
          amountUSD: t.amountUSD,
          needsComplete: "true",
          feeTo: '0',
          feeLiquidity: '0'
        }))

        const buy_swap = SWAPS.data.transactions.filter(t => t.swapSlide === 'Buy');
        const sell_swap = SWAPS.data.transactions.filter(t => t.swapSlide === 'Sell');
        const swaps_res = SWAPS.data.transactions.map(t => {
          const sell = sell_swap.find(n => n.id === t.id);
          const buy = buy_swap.find(n => n.id === t.id);
          return {
            id: t.id,
            transaction: {
              id: t.txId,
              timestamp: t.timestamp
            },
            timestamp: t.timestamp,
            pair: t.pair,
            to: t.sender,
            sender: t.sender,
            // TO DO
            amount0In: sell ? sell.amount0 : "0",
            amount1In: buy ? buy.amount1 : "0",
            amount0Out: buy ? buy.amount0 : "0",
            amount1Out: sell ? sell.amount1 : "0",
            logIndex: '0',
            amountUSD: t.amountUSD
          }
        })



        let result = {
          data: {
            mints: mints_res,
            burns: burns_res,
            swaps: swaps_res
          }
        }
        if (result?.data) {
          updateTransactions(account, result?.data)
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (!transactions && account) {
      fetchData(account)
    }
  }, [account, transactions, updateTransactions])

  return transactions || {}
}
